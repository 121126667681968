const sendFbEvent = (eventName, payload) => {
  if (typeof window.fbq === 'function') {
    window.fbq('track', eventName, payload)
  }
}

const sendGaEvent = (eventName, payload) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', eventName, payload)
  }
}

const getItemData = (projectId, value) => {
  return {
    currency: 'TWD',
    value,
    items: [{
      item_id: `${projectId}.${value}`,
      item_name: `${projectId}.${value}`,
    }],
    item: `${projectId}.${value}`,
  }
}

export const addToCartEvent = (projectId, amount) => {
  console.log('add', projectId, amount, 'to cart')
  const itemData = getItemData(projectId, amount)
  sendGaEvent('add_to_cart', itemData)
  sendFbEvent('AddToCart', {
    content_name: itemData.item,
    value: itemData.value,
    currency: itemData.currency,
  })
}

export const removeFromCartEvent = (projectId, amount) => {
  console.log('remove', projectId, 'from cart')
  sendGaEvent('remove_from_cart', getItemData(projectId, amount))
}

export const viewContentEvent = (projectId, amount) => {
  console.log('view', projectId, 'detail')
  sendGaEvent('view_item', getItemData(projectId, amount))
}

