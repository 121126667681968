const timeout = (dur, res = '') =>
  new Promise((resolve) => {
    window.setTimeout(() => resolve(res), dur)
  })

const getClientId = async () => {
  const task = new Promise((resolve) => {
    if (typeof window.gtag === 'function') {
      window.gtag('get', 'client_id', resolve)
    } else {
      resolve('')
    }
  })
  const clientId = await Promise.race([task, timeout(50)])
  return clientId
}

export default getClientId
