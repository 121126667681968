import { Circle, Container, Link } from '@chakra-ui/layout'
import React, { useCallback } from 'react'

import heart_red from './heart.png'
// import heartbroken from './heartbroken.png'
import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import Text from '../../../components/Text'
import BackgroundImage from '../../../components/BackgroundImage'
import Title from './Title'

const Suggestion = ({ i, t, ...props }) => (
  <Flex mt={i && "1em"} {...props}>
     <Circle fontFamily="dokdo" mt="0.375rem" mr="0.5rem" size="1.125rem" bg="love.title" fontSize="0.875em" color="white">
      {i + 1}
    </Circle>
    <Text lineHeight="2" textAlign="justify">{t}</Text>
  </Flex>
)

const Details = ({ resultData, isLove, person }) => {
  const personize = useCallback((str) => person ? str?.replace(/你/g, person) : str, [person])
  const feels = [
    {
      src: heart_red,
      logoWidth: '1.25em',
      ratio: 37 / 35,
      title: '最能讓你感受到愛的是',
      desc: resultData?.result.can,
      love: resultData?.result.love,
    },
    // {
    //   src: heartbroken,
    //   logoWidth: '1.375em',
    //   ratio: 44 / 34,
    //   title: '這些舉動無法讓你感受到愛',
    //   desc: resultData?.result.cant
    // },
  ]
  return (
    <>
      <Box bg="white">
        <Container px="3.125em" maxW="container.sm">
          {isLove ? (
            <Box pb="1.25em">
              {feels.map((f, i) => (
                <Box pt="2.5rem" key={i}>
                  <Flex alignItems="center" color="love.title">
                    <Box width={f.logoWidth} mr="0.5rem">
                      <BackgroundImage src={f.src} ratio={f.ratio} />
                    </Box>
                    <Text fontWeight="bold" fontSize="1.25em" letterSpacing="0.125rem">{personize(f.title)}『{f.love}』</Text>
                  </Flex>
                  <Text mt="0.5rem" textAlign="justify" lineHeight="2" color="love.text">{personize(f.desc)}</Text>
                </Box>
              ))}
            </Box>
          ) : (
            <Box pt="3.5em" pb="1.875em">
              <Text fontWeight="bold" fontSize="1.25em" color="love.title">{resultData?.result.title}</Text>
              <Text mt="1rem" textAlign="justify" lineHeight="2" color="love.text">{resultData?.result.subtitle}</Text>
            </Box>
          )}
          <Box pb="2em" >
            <Text lineHeight="2" color="love.text">
              ＊若想看完整版『愛之語』介紹，請見<Link target="_blank" fontWeight="bold" color="love.primary" href="/love-languages/">『愛之語全特輯』</Link>
            </Text>
          </Box>
        </Container>
      </Box>
      <Container px="1.5em" pt="1.875em" pb="1.375em" maxW="container.sm">
        <Title text={isLove ? '親密關係中的提醒' : '你需要這三種朋友'} isLove={isLove} color="love.title" />
        <Box mt="1.5em" bg="white" p="1.625em" borderRadius="1.5em">
          {isLove ? resultData?.result.note.map((t, i) => (
            <Suggestion t={t} i={i} key={i} />
          )) : resultData?.result.friends.map((t, i) => (
              <Suggestion t={t} i={i} key={i} />
            ))}
        </Box>
      </Container>
    </>
  )
}

export default Details
