import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  forwardRef,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import React, { createElement, useCallback, useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'

import Text from '../../../components/Text'
import getClientId from '../../../utils/getClientId'
import getFbClientId from '../../../utils/getFbClientId'
import Title from './Title'
import DrawButton from '../../../components/DrawButton'
import { useSearchParam } from 'react-use'
import { addToCartEvent } from '../../../utils/gaEvents'

const apiPathname = 'https://asia-east1-re-payment-90e7a.cloudfunctions.net/requestCreateOrder'
// const apiPathname =
//   'http://localhost:5001/re-payment-90e7a/asia-east1/requestCreateOrder'

const amounts = [77, 340, 520, 1314]

const paymentMethods = [
  { label: '綠界信用卡', value: 'ecpay' },
  { label: 'LINE Pay', value: 'linepay' },
]

const invoices = [
  { label: '捐贈(聯合勸募)', value: 'donate' },
  { label: '二聯式發票', value: 'b2c' },
  { label: '三聯式發票', value: 'b2b' },
]

const emailField = {
  label: 'E-mail',
  name: 'email',
  type: 'email',
  helper: '電子發票將寄至此信箱',
}

const b2bFields = [
  { name: 'company', label: '抬頭' },
  { name: 'companyId', label: '統編' },
]

const invoicesFollowUp = {
  b2c: [emailField],
  b2b: [emailField, ...b2bFields],
}

const fields = [
  {
    label: '支付方式',
    type: 'radio',
    options: paymentMethods,
    name: 'paymentMethod',
  },
  { label: '發票方式', type: 'select', options: invoices, name: 'invoice' },
]

const InputRadio = ({ options, type, ...props }) => {
  return (
    <RadioGroup {...props}>
      <Stack direction="row">
        {options.map(({ label, value }) => (
          <Radio value={value} key={value}>
            {label}
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  )
}

const InputSelect = forwardRef(({ options, type, ...props }, ref) => {
  return (
    <Select {...props} ref={ref}>
      {options.map(({ label, value }) => (
        <option value={value} key={value}>
          {label}
        </option>
      ))}
    </Select>
  )
})

const NiceInput = forwardRef((props, ref) => (
  <Input ref={ref} variant="outline" {...props} />
))

const inputs = {
  radio: InputRadio,
  select: InputSelect,
}

const FormField = ({
  label,
  control,
  name,
  placeholder,
  type,
  options,
  helper,
}) => {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        rules={{ required: true }}
        render={({ field }) =>
          createElement(inputs[type] || NiceInput, {
            ...field,
            placeholder,
            type,
            options,
          })
        }
      />
      {helper && <FormHelperText>{helper}</FormHelperText>}
    </FormControl>
  )
}

const projectId = 'superquiz-love'

const DonateButton = ({ amount, isLove, answerId }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { control, watch, handleSubmit, formState } = useForm({
    defaultValues: {
      paymentMethod: 'ecpay',
      invoice: 'donate',
      loveCode: '7505',
    },
  })
  const ecpayFormRef = useRef()

  const handleCreateOrder = async (formData) => {
    const clientId = await getClientId()
    const { fbp, fbc } = getFbClientId()
    const res = await fetch(apiPathname, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount,
        clientId,
        fbp,
        fbc,
        location: window.location.href,
        projectId,
        answerId,
        ...formData,
      }),
    })
    const data = await res.json()
    if (data.web) {
      setTimeout(() => {
        window.location = data.web
      })
    } else if (data.html && data.action) {
      ecpayFormRef.current.innerHTML = data.html
      ecpayFormRef.current.action = data.action
      ecpayFormRef.current.submit()
    }
  }
  const invoice = watch('invoice')

  return (
    <>
      <DrawButton
        onClick={() => {
          onOpen()
          addToCartEvent(projectId, amount)
        }}
      >
        NT ${amount.toLocaleString()}
      </DrawButton>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p="4"
          borderRadius={'1.25rem'}
          overflow="hidden"
          bg="white"
        >
          <form id="_form_aiochk" ref={ecpayFormRef} method="POST" />
          <form onSubmit={handleSubmit(handleCreateOrder)}>
            <ModalHeader color="love.title">
              支持 NT ${amount.toLocaleString()}
            </ModalHeader>
            <ModalBody as={Stack} spacing={4}>
              {fields.concat(invoicesFollowUp[invoice] || []).map((field) => (
                <FormField key={field.name} {...field} control={control} />
              ))}
            </ModalBody>
            <ModalFooter maxW="16em" mx="auto">
              <DrawButton type="submit" isLoading={formState.isSubmitting}>
                付款
              </DrawButton>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

const Donate = ({ answerId, isLove }) => {
  const status = useSearchParam('status')
  const ref = useRef()
  useEffect(() => {
    if (status === 'paid') {
      ref.current.scrollIntoView()
    }
  }, [status])
  return (
    <Container px="3.125em" py="2em" maxW="container.sm" ref={ref}>
      <Title text="打賞支持" isLove={isLove} color="love.title" />
      <Text
        fontWeight="bold"
        lineHeight="1.77"
        letterSpacing="0.125rem"
        fontSize="1.25rem"
        mt="1.25rem"
        mb="0.5rem"
        color="love.desc"
      >
        若喜歡我們的測驗，請支持我們，讓我們能夠持續為你帶來更多有趣的測驗！
      </Text>
      <SimpleGrid columns={2} spacing="1.25rem" mt="1.25rem">
        {amounts.map((amount) => (
          <DonateButton
            key={amount}
            amount={amount}
            answerId={answerId}
            isLove={isLove}
          />
        ))}
      </SimpleGrid>
      {status === 'paid' && (
        <Text
          color="love.desc"
          fontWeight="bold"
          fontSize="xl"
          textAlign="center"
          pt="4"
        >
          ❤️❤️感謝你的支持！❤️❤️
        </Text>
      )}
    </Container>
  )
}

export default Donate
