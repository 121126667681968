import { Container, Stack, VStack } from '@chakra-ui/layout'
import { navigate } from 'gatsby-link'
import React, { useEffect } from 'react'
import { useLocalStorage } from 'react-use'

import Radar from './radar'
import Box from '../../../components/Box'
import Text from '../../../components/Text'
import DrawButton from '../../../components/DrawButton'
import CenterSpinner from '../../../components/CenterSpinner'
// import FormField from '../../../components/FormField';

import useScores from '../useScores'
import useResult from './useResult'
import useShare from '../../../utils/useShare'
import withFirestoreData from '../../../utils/withFirestoreData'
import useGatsbySearchParams from '../../../utils/useGatsbySearchParams'

import Title from './Title'
import Wishes from './Wishes'
import HeartAnimals from './heartAnimals'
import Details from './Details'
import Subscribe from './Subscribe'
import Donate from './Donate'

const Result = ({ state, pageContext }) => {
  // const [invitationSent] = useLocalStorage(`superquiz:love-languages:${state?.firestoreId}:invited`)
  const [invitatioAccepted] = useLocalStorage(`superquiz:love-languages:${state?.invitationId}:accepted`)
  // const { control } = useFormContext()
  // const { isSubmitting } = useFormState({ control })
  const myAnswerId = state?.myAnswerId || state?.firestoreId
  useEffect(() => {
    if (!state || !state.scores) {
      navigate(`/q/love-languages/`, { replace: true })
    }
  }, [state])
  const resultData = useResult(state?.scores, pageContext.questions)
  const isLove = pageContext.id === 'lover'
  // console.log(resultData)
  const onShareMyResult = useShare(`/q/love-languages/${pageContext.id}/share/${resultData?.code}/`)
  const onShareQuiz = useShare(`/q/love-languages/`)
  const isOther = myAnswerId !== state?.firestoreId
  const person = isOther ? '他' : '你'
  const isDual = state?.mode === 'dual'

  if (!myAnswerId) return null
  return (
    <>
      <HeartAnimals results={[resultData]} isLove={isLove} person={person} />
      <Container pt="1.75rem" pb="2.25em" as={Stack} spacing="4" maxW="container.sm">
        <Title
          text={`${person}的愛之語全分析`}
          isLove={isLove}
          color="love.title"
        />
        {state?.scores && <Radar mt="0.625em" scores={state.scores} isLove={isLove} color="love.title" />}
        {/* <Text px="2.125rem" lineHeight="1.75" color="love.text">
          愛之語有五種，如果你有1-2種很高分是正常的，但如果有三種以上很高分，建議你先不要參考本測驗建議，過一段時間後再測一次。
        </Text> */}
      </Container>
      <Details resultData={resultData} isLove={isLove} person={person} />
      <Box pt="2.75em" pb="3em" bg="love.resultBg">
        <Container maxW="container.sm">
          <Title text={isDual ? '限時免費體驗雙人分析' : '分享測驗'} isLove={isLove} color="love.title" />
          <VStack mt="1.5em" bg={isDual && "white"} p="1.625em" borderRadius="1.5em" spacing="4">
            {isDual && (
              <>
                <Text lineHeight="2" color="love.text">
                  從自己的完整分佈比例，讓愛你的人知道最適合「愛你的方式」，邀請情人作答，即可看到雙人分析結果<br />
                  ＊一定要用下方專屬連結邀請
                </Text>
                {state?.invitationId && !invitatioAccepted && !state?.isDualView && (
                  <DrawButton
                    fontSize="1em"
                    fontWeight="500"
                    to={`/q/love-languages/${pageContext.id}/dual/accept/`}
                    state={{ ...state, isLater: true }}
                  >同意產生雙人報告</DrawButton>
                )}
                {state?.dualId ? (
                  <DrawButton
                    fontSize="1em"
                    fontWeight="500"
                    to={`/q/love-languages/${pageContext.id}/dual/result/?key=${myAnswerId}&id=${state.dualId}`}
                  >檢視雙人報告</DrawButton>
                ) : (
                  <DrawButton
                    fontSize="1em"
                    fontWeight="500"
                    to={`/q/love-languages/${pageContext.id}/invite/`}
                    state={state}
                  >邀請情人填寫並收到雙人分析</DrawButton>
                )}
              </>
            )}
            <DrawButton
              fontSize="1em"
              fontWeight="500"
              onClick={onShareMyResult}
            >分享個人測驗結果</DrawButton>
            <DrawButton.White fontSize="1em" fontWeight="500" color="love.title" onClick={onShareQuiz}>單純分享測驗</DrawButton.White>
          </VStack>
        </Container>
      </Box>
      <Donate answerId={myAnswerId} isLove={isLove} />
      {!isOther && (
        <>
          <Box bg="white" py="1.875em">
            <Wishes state={state} isLove={isLove} />
          </Box>
          <Subscribe state={state} />
        </>
      )}
    </>
  )
}

const SavedResult = ({ firestoreData, answerId, ...props }) => {
  const answers = firestoreData.data?.answers
  const scores = useScores(answers)
  const state = {
    ...props.location.state,
    scores,
    firestoreId: answerId,
    relation: answers?.relation,
    invitationId: answers?.invitationId,
    mode: firestoreData.data?.mode,
    email: answers?.email,
    dualId: firestoreData.data?.dualId,
  }
  return firestoreData.status === 'loading' ? <CenterSpinner /> : <Result state={state} {...props} />
}

const EnhancedResult = withFirestoreData(SavedResult)

const HandleFiresoreData = (props) => {
  const answerId = useGatsbySearchParams(props.location, 'key')
  return answerId ? <EnhancedResult firestorePath={`answers/${answerId}`} answerId={answerId} {...props} /> : <Result state={props.location.state} {...props} />
}

export default HandleFiresoreData
