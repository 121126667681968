function getFbClientId() {
  const fbp = /_fbp=(fb\.\d\.\d+\.\d+)/.exec(window.document.cookie)
  const fbc = /_fbc=(fb\.\d\.\d+\.\w+)/.exec(window.document.cookie)
  return {
    fbp: fbp?.[1] ?? '',
    fbc: fbc?.[1] ?? '',
  }
}

export default getFbClientId
