import { useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-use'
import Bowser from 'bowser'

const useShare = (path) => {
  const location = useLocation()
  const href = location.origin + path
  const virtualHerf = useRef()
  useEffect(() => {
    const a = document.createElement('a')
    a.href = `https://www.facebook.com/sharer/sharer.php?u=${href}`
    a.target = '_blank'
    document.body.append(a)
    virtualHerf.current = a
    return () => a.remove()
  }, [path])
  return useCallback(async () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isDesktop = browser.getPlatformType() === 'desktop'
    if (isDesktop) {
      if (virtualHerf.current) virtualHerf.current.click()
    } else {
      try {
        await navigator.share({ url: href })
      } catch (err) {
        if (err.name !== 'AbortError') {
          if (virtualHerf.current) virtualHerf.current.click()
        }
        console.error(err)
      }
    }
  }, [href])
}

export default useShare
